import { useState } from "react";
import "./or-shipping.css";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";

interface MlShippingProps {
  text: string;
}

const MlShipping: React.FC<MlShippingProps> = () => {
  const [selected, setSelected] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className="pl-80 pr-80 pt-20">
      <div>
        <label
          htmlFor="email"
          className="block text-sm/6 font-medium text-gray-900"
        >
          Address
        </label>
        <div className="mt-2">
          <input
            id="fullname"
            name="fullname"
            type="text"
            required
            autoComplete="email"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
            placeholder=" Example: Saint Vincent 1414, Lyon"
            // onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="email"
          className="block text-sm/6 font-medium text-gray-900"
        >
          Apartment, suite, etc.
        </label>
        <div className="mt-2">
          <input
            id="apartment"
            name="apartment"
            type="text"
            required
            autoComplete=""
            placeholder="Example: Apartment 1B"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
            // onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div>
        <div className="mt-2 flex flex-row">
          <div className="w-full">
            <label className="block text-sm/6 font-medium text-gray-900">
              Country
            </label>
            <ReactFlagsSelect
              selected={selected}
              searchable
              searchPlaceholder="Search countries 🔎"
              onSelect={(code) => setSelected(code)}
              className="w-full"
            />
          </div>
          <div className="ml-4 w-full">
            <label className="block text-sm/6 font-medium text-gray-900">
              City
            </label>
            <input
              id="city"
              name="city"
              type="text"
              required
              autoComplete=""
              placeholder="Example: Lyon"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              // onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mt-2 flex flex-row w-full">
          <div className="w-full mr-1">
            <div className="w-full">
              <label className="block text-sm/6 font-medium text-gray-900">
                Phone
              </label>
              <PhoneInput
                country={"us"} // País predeterminado
                value={phone}
                onChange={(e) => setPhone(e)}
              />
            </div>
          </div>
          <div className="w-full ml-4">
            <div className="w-full">
              <label className="block text-sm/6 font-medium text-gray-900">
                Postal code
              </label>
              <input
                id="postal-code"
                name="postal-code"
                type="number"
                required
                autoComplete=""
                placeholder="Example: 65003"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MlShipping;
