import React from "react";
import "./ml-hero.css";

interface MlHeroProps {
  media: any;
}

const MlHero: React.FC<MlHeroProps> = () => {
  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full textMlHero">
        <p className="textMlHeroInicio">
          Handmade crochet products, made with love and dedication, ready to
          give a special touch to your life.
        </p>
      </div>
      <div className="bg-hero-image flex h-full w-full">
        <div className="flex h-full w-full justify-center items-center"></div>
      </div>
    </div>
  );
};

export default MlHero;
