import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./ml-carrousel.css";
import { Navigation, Pagination } from 'swiper/modules';
import { db } from "../../config/firebase-config";
import { getDocs, collection } from "firebase/firestore";
import { useEffect, useState } from "react";


export default function Example() {
  const productCollectionRef = collection(db, "productos");

  const [productList, setProductList] = useState<
  {
    id: string;
    desc: string;
    href: number;
    imageAlt: string;
    name: string;
    price: number;
    srcImage: string;
  }[]
>([]);

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = async () => {
    try {
      const data = await getDocs(productCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        id: doc.id,
        desc: doc.data().desc || "", // Descripción del producto
        href: doc.data().href || 0, // Enlace del producto (predeterminado a "#")
        imageAlt: doc.data().imageAlt || "", // Texto alternativo de la imagen
        name: doc.data().name || "", // Nombre del producto
        price: doc.data().price || 0, // Precio del producto
        srcImage: doc.data().srcImage || "", // URL de la imagen
        type: doc.data().type || 0, // Tipo de producto
      }));
      setProductList(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex h-full w-full divCarousel">
      <Swiper
        spaceBetween={16}
        slidesPerView={3}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        allowSlideNext
        allowSlidePrev
        pagination={{ clickable: true }}
        autoplay={{ delay: 500 }}
        modules={[Navigation, Pagination]}
        // modules={[Navigation, Pagination]}
      >
        {productList.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="group relative">
              <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.srcImage}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href={"#"}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{product.desc}</p>
                </div>
                <p className="text-sm font-medium text-gray-900">
                  {product.price}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
