import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";
import "./ml-navbar.css";
import { useCart } from "../context/cart-context";
import { FaHouse } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaStore } from "react-icons/fa";
import { auth, db } from "../../config/firebase-config";
import { doc, collection, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const MlNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { toggleCart } = useCart(); // Usa el contexto para controlar el carrito
  const [itemCar, setItemCar] = useState(0);
  const [currentUser, setCurrentUser] = useState(null); // Estado para guardar el usuario actual

  useEffect(() => {
    // Escuchar cambios en el estado de autenticación del usuario
    const unsubscribeAuth = onAuthStateChanged(auth, (user:any) => {
      if (user) {
        setCurrentUser(user); // Guardar el usuario autenticado
      } else {
        setCurrentUser(null); // No hay usuario autenticado
        setItemCar(0); // Restablecer el contador del carrito si no hay usuario
      }
    });

    return () => unsubscribeAuth(); // Limpiar el listener al desmontar el componente
  }, []);

  useEffect(() => {
    const fetchCartItems = () => {
      // Obtener el usuario actual
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.log("Usuario no autenticado.");
        return;
      }

      const cartRef = doc(db, "carts", currentUser.uid); // Referencia al documento del carrito del usuario

      // Suscribirse a los cambios en tiempo real del documento del carrito
      const unsubscribe = onSnapshot(cartRef, (docSnap) => {
        if (docSnap.exists()) {
          const cartData = docSnap.data();
          const items = cartData.items || []; // Asegúrate de que sea un array
          
          // Sumar la cantidad total de ítems en el carrito
          const totalQuantity = items.reduce((total:any, item:any) => total + item.quantity, 0);

          setItemCar(totalQuantity); // Actualizar el estado con la cantidad total
        } else {
          console.log("El carrito no existe.");
          setItemCar(0); // Si no hay carrito, muestra 0
        }
      });

      // Limpiar la suscripción al desmontar el componente
      return () => unsubscribe();
    };

    fetchCartItems();
  },[currentUser]); // Solo se ejecuta al montar el componente

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="flex w-full">
      <header>
        {/* <!-- lg+ (Escritorio) --> */}
        <div className="bg-white border-b border-gray-200">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between h-16 lg:h-20">
              {/* Menú de escritorio */}
              <div className="hidden lg:flex lg:items-center lg:space-x-10">
                <Link
                  to="/login"
                  className="text-base font-medium text-white cursor-pointer items-center button-cart"
                >
                  LOGIN
                  <FaUser className="iconNavbar" />
                </Link>

                <Link
                  to="/home"
                  className="h-full w-full text-base font-medium text-white cursor-pointer items-center button-cart"
                >
                  HOME
                  <FaHouse className="iconNavbar" />
                </Link>
              </div>

              {/* Logo */}
              <div className="lg:absolute lg:-translate-x-1/2 lg:inset-y-5 lg:left-1/2">
                <div className="flex-shrink-0">
                  <Link to="/home" className="flex">
                    <img
                      className="imagenLogo"
                      src={`${process.env.PUBLIC_URL}/assets/imgs/logo2.png`}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>

              {/* Menú de escritorio y carrito */}
              <div className="hidden lg:flex lg:items-center lg:space-x-10">
                <Link
                  to="/boutique"
                  className="text-base font-medium text-white cursor-pointer items-center button-cart"
                >
                  STORE
                  <FaStore className="iconNavbar" />
                </Link>

                {/* Icono del carrito */}
                <div
                  onClick={toggleCart}
                  className="cursor-pointer flex items-center button-cart"
                >
                  <span className="text-base font-medium text-white">CART</span>
                  <FaCartShopping className="iconNavbar"></FaCartShopping>
                  <span className="text-base font-medium text-white pl-2">
                    {itemCar}
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {/* Menú móvil desplegable */}
        {menuOpen && (
          <div className="bg-white lg:hidden">
            <nav className="px-4 py-4 mx-auto sm:px-6 lg:px-8">
              <div className="flex flex-col space-y-2">
                <Link
                  to="/features"
                  className="py-2 text-base font-medium text-black"
                >
                  Features
                </Link>
                <Link
                  to="/solutions"
                  className="py-2 text-base font-medium text-black"
                >
                  Solutions
                </Link>
                <Link
                  to="/resources"
                  className="py-2 text-base font-medium text-black"
                >
                  Resources
                </Link>
                <Link
                  to="/pricing"
                  className="py-2 text-base font-medium text-black"
                >
                  Pricing
                </Link>
              </div>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-col space-y-2">
                <Link
                  to="/signup"
                  className="py-2 text-base font-medium text-black"
                >
                  Sign up
                </Link>
                <Link
                  to="/signin"
                  className="py-2 text-base font-medium text-black"
                >
                  Sign in
                </Link>
              </div>
            </nav>
          </div>
        )}
      </header>
    </div>
  );
};

export default MlNavbar;
